<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="LBLBASEINFO">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn 
                v-show="editable&&!disabled" 
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="month"
                mappingType="PUT"
                label="완료" 
                icon="check"
                @beforeAction="completeTargetDept"
                @btnCallback="completeTargetDeptCallback" />
              <!--저장-->
              <c-btn 
                v-show="editable&&!disabled" 
                :isSubmit="isSave"
                :url="updateUrl"
                :param="month"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveTargetMonth"
                @btnCallback="saveTargetMonthCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-datepicker
                :editable="editable"
                :disabled="true"
                type="month"
                label="년월"
                name="yearmonth"
                v-model="month.yearmonth"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                :editable="editable"
                :disabled="true"
                :data="month"
                deptValue="manageSupervisorDeptCd"
                type="dept_user"
                label="관리감독자"
                name="manageSupervisorId"
                v-model="month.manageSupervisorId"
              />
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-table
      ref="table"
      class="q-mt-sm"
      :title="`${$comm.moment(month.yearmonth).format('YYYY년 MM월')} 부서 실적 현황`"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="gridData"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :usePaging="false"
      :isExcelDown="true"
      :filtering="false"
      :isFullScreen="true"
      :columnSetting="false"
    >
      <template slot="suffixTitle">
        <font v-if="popupParam.deptCd === 'ETC'" style="font-size:0.9em;font-weight:600;color:red;">
          (※ 팀이 기타인 경우 EHS에서 자동으로 데이터를 들고 올 수 없음으로 직접 입력하시기 바랍니다.)
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safetyHealthyTargetMonth',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiSafetyHealthyTargetMonthId: '',
        deptCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      month: {
        saiSafetyHealthyTargetMonthId: '',  // 안전보건목표 일련번호
        saiSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        yearmonth: '',  // 년월
        saiSafetyHealthyTargetMonthStepCd: '',  // 진행상태
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        manageSupervisorId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        depts: [],
      },
      holidaies: [],
      grid: {
        merge: [{ index: 0, colName: "targetName" }],
        columns: [],
        data: [],
      },
      editable: false,
      isSave: false,
      isComplete: false,
      detailUrl: '',
      holidayUrl: '',
      updateUrl: '',
      completeUrl: '',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = (this.contentHeight - 180);
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    gridData() {
      let data = [];
      if (this.month && this.month.depts && this.month.depts.length > 0) {
        data = this.month.depts[0].results;
      }
      return data;
    },
    disabled() {
      let havaData = this.month && this.month.depts && this.month.depts.length > 0
      return this.month.saiSafetyHealthyTargetMonthStepCd === 'SSHTM00002'
        // 결재중인 경우 비활성화
        || this.month.approvalStatusCd === 'ASC0000001'
        || havaData ? this.month.depts[0].completeFlag === 'Y' : true
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.safetyHealthy.month.get.url;
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      this.updateUrl = transactionConfig.sai.safetyHealthy.month.update.url;
      this.completeUrl = transactionConfig.sai.safetyHealthy.dept.complete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.saiSafetyHealthyTargetMonthId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          saiSafetyHealthyTargetMonthId: this.popupParam.saiSafetyHealthyTargetMonthId,
          deptCd: this.popupParam.deptCd ? this.popupParam.deptCd : this.$store.getters.user.deptCd
        };
        this.$http.request((_result) => {
          this.$_.extend(this.month, _result.data);

          this.getHolidaies();
        },);
      } else {
        // 신규등록 * 해당 경우로 들어오지 않음
      }
    },
    getHolidaies() {
      this.$http.url = this.holidayUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.$comm.moment(this.month.yearmonth).format('YYYY')
      };
      this.$http.request((_result) => {
        this.holidaies = _result.data
        this.setGridHeader()
      },);
    },
    setGridHeader() {
      let yearmonth = this.month.yearmonth;
      let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();
      let childCols = [];
      for (let idx = 1; idx <= yearmonthDays; idx++) {
        let isHoliday = this.isHoliday(`${yearmonth}-${this.$_.padStart(idx, 2, '0')}`);
        childCols.push({
          name: `day${idx}`,
          field: `day${idx}`,
          label: `${idx}`,
          align: 'center',
          style: 'width:30px',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          color: 'blue', 
          month: 1,
          disableTarget: this.popupParam.deptCd !== 'ETC' ? 'ehsFlag' : '',
          disableCon: 'N',
          headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
          tdColor: isHoliday ? '#D9D9D9' : null
        })
      }
      let performanceCols = {
        name: 'performance',
        field: 'performance',
        label: this.$comm.moment(this.month.yearmonth).format('YYYY년 MM월'),
        child: childCols
      };
      this.grid.columns = [
        {
          name: 'targetName',
          field: 'targetName',
          label: '구분',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'detailName',
          field: 'detailName',
          label: '점검내용',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          fix: true,
        },
        {
          name: 'inspectionCycle',
          field: 'inspectionCycle',
          label: '점검주기',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          fix: true,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: '담당자',
          align: 'left',
          style: 'width:150px',
          sortable: false,
          fix: true,
        },
        {
          name: 'result',
          field: 'result',
          label: '점검결과',
          align: 'center',
          child: [
            {
              name: 'resultTeamLeaderName',
              field: 'resultTeamLeaderName',
              label: '해당팀장',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'resultSupportTeamName',
              field: 'resultSupportTeamName',
              label: '생산지원팀',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'resultManagerName',
              field: 'resultManagerName',
              label: '관리감독자',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
          ]
        },
        {
          name: 'relatedEvidence',
          field: 'relatedEvidence',
          label: '관련근거',
          align: 'left',
          style: 'width:270px',
          type: 'html',
          sortable: false
        },
      ];
      this.grid.columns.splice(4, 0, performanceCols)
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else if (this.$_.findIndex(this.holidaies, { holiday: day }) > -1) {
        return true;
      } else {
        return false;
      }
    },
    saveTargetMonth() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.month.regUserId = this.$store.getters.user.userId
              this.month.chgUserId = this.$store.getters.user.userId
      
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetMonthCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeTargetDept() {      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.month.regUserId = this.$store.getters.user.userId
              this.month.chgUserId = this.$store.getters.user.userId
      
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeTargetDeptCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>